import {
  ITimeUsage,
  ITranslationUsage,
  TimeSlice,
  UserID,
} from "byrdhouse-types";
import { useCallback, useEffect, useMemo, useState } from "react";
import "./TranslationUsage.css";
import DatePicker from "react-datepicker";
import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
} from "chart.js";
import "chartjs-adapter-date-fns";
import { enUS } from "date-fns/locale";
import Button from "./Button";
import api from "../api";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend
);

ChartJS.defaults.backgroundColor = "#ccc";
ChartJS.defaults.borderColor = "#ccc";
ChartJS.defaults.color = "#ccc";

type UsageProps = {
  providerId: UserID;
};

const Usage = (props: UsageProps) => {
  // Caption usage (individual captions and characters translated)
  const [, setUsages] = useState<ITranslationUsage[]>([]);

  // Time usage (in-call and translation time)
  const [timeUsages, setTimeUsages] = useState<ITimeUsage[]>([]);

  const [datasetId, setDatasetId] = useState(Date.now().toString());

  const now = useMemo(() => {
    return new Date();
  }, []);

  const TIME_UNITS = [
    "second",
    "minute",
    "hour",
    "day",
    "week",
    "month",
  ] as const;
  type TimeUnit = (typeof TIME_UNITS)[number];
  const [selectedTimeUnit, setSelectedTimeUnit] = useState<TimeUnit>("hour");

  const last24Hours: TimeSlice = useMemo(() => {
    const start = new Date(now);
    start.setHours(now.getHours() - 24);
    return {
      start: start,
      end: now,
    };
  }, [now]);

  const [timeSlice, setTimeSlice] = useState<TimeSlice>(last24Hours);

  const getUserTranslationUsage = useCallback(
    async (id: UserID, timeSlice: TimeSlice) => {
      const response = await api.getUserTranslationUsage({ id, timeSlice });
      setUsages(response.usages);
      setDatasetId(Date.now().toString());
    },
    []
  );

  const getUserTimeUsage = useCallback(
    async (id: UserID, timeSlice: TimeSlice) => {
      const response = await api.getUserTimeUsage({ id }, { timeSlice });
      setTimeUsages(response.usages);
      setDatasetId(Date.now().toString());
    },
    []
  );

  useEffect(() => {
    // Get usages for time slice
    getUserTranslationUsage(props.providerId, timeSlice);
    getUserTimeUsage(props.providerId, timeSlice);
  }, [timeSlice, getUserTranslationUsage, getUserTimeUsage, props.providerId]);

  // const userUsages = useMemo(() => {
  //   return usages.reduce(
  //     (
  //       result: { [userId: UserID]: ITranslationUsage[] },
  //       usage: ITranslationUsage
  //     ) => {
  //       const userId = usage.userId;
  //       if (!result[userId]) {
  //         result[userId] = [];
  //       }
  //       result[userId].push(usage);
  //       return result;
  //     },
  //     {}
  //   );
  // }, [usages]);

  // type ParticipantTimeUsage = {
  //   translationUsageInSeconds: number;
  //   inCallTimeInSeconds: number;
  // };

  // const participantUsages = useMemo(() => {
  //   return timeUsages.reduce(
  //     (
  //       result: { [userId: UserID]: ParticipantTimeUsage },
  //       current: ITimeUsage
  //     ) => {
  //       const existing = result[current.userId];
  //       if (existing) {
  //         existing.inCallTimeInSeconds += current.intervalInSeconds;
  //         if (!current.muted) {
  //           existing.translationUsageInSeconds += current.intervalInSeconds;
  //         }
  //       } else {
  //         result[current.userId] = {
  //           translationUsageInSeconds: !current.muted
  //             ? current.intervalInSeconds
  //             : 0,
  //           inCallTimeInSeconds: current.intervalInSeconds,
  //         };
  //       }
  //       return result;
  //     },
  //     {}
  //   );
  // }, [timeUsages]);

  // const totalInCallTimeInSeconds = useMemo(() => {
  //   console.debug(
  //     "total time usages",
  //     timeUsages.length,
  //     timeUsages.reduce((result: ITimeUsage[], current) => {
  //       if (!result.find((item) => item.timestamp === current.timestamp)) {
  //         result.push(current);
  //       }
  //       return result;
  //     }, []).length
  //   );
  //   return timeUsages.reduce(
  //     (result, current) => result + current.intervalInSeconds,
  //     0
  //   );
  // }, [timeUsages]);

  const totalTranslationUsageInSeconds = useMemo(() => {
    return timeUsages.reduce(
      (acc, curr) => acc + (!curr.muted ? curr.intervalInSeconds : 0),
      0
    );
  }, [timeUsages]);

  // const totalCaptionUsageInSeconds = useMemo(() => {
  //   const minSecondsOfSilence = 60;
  //   const totalUsage = Object.values(userUsages).reduce(
  //     (totalSum, userUsage) => {
  //       totalSum += userUsage.reduce(
  //         (userSum: number, curr: ITranslationUsage, index) => {
  //           if (index > 0) {
  //             const prev = userUsage[index - 1];
  //             const silenceInSeconds =
  //               (new Date(curr.startTimestamp).getTime() -
  //                 new Date(prev.endTimestamp).getTime()) /
  //               1000;
  //             const usageInSeconds =
  //               (new Date(curr.endTimestamp).getTime() -
  //                 new Date(prev.startTimestamp).getTime()) /
  //               1000;
  //             if (silenceInSeconds < minSecondsOfSilence) {
  //               // If difference in timestamps is less than min unspoken time, add to sum
  //               userSum += usageInSeconds;
  //             }
  //           }
  //           return userSum;
  //         },
  //         0
  //       );
  //       return totalSum;
  //     },
  //     0
  //   );
  //   return totalUsage;
  // }, [userUsages]);

  // const totalCaptionsTranslated = useMemo(() => {
  //   return usages.length;
  // }, [usages]);

  // const finalCharactersTranslated = useMemo(() => {
  //   return usages.reduce(
  //     (acc, curr) => acc + curr.finalCharactersTranslated,
  //     0
  //   );
  // }, [usages]);

  // const totalCharactersTranslated = useMemo(() => {
  //   return usages.reduce(
  //     (acc, curr) => acc + curr.totalCharactersTranslated,
  //     0
  //   );
  // }, [usages]);

  // Datasets

  type TimeUsageGrouped = {
    timestamp: Date;
    inCallTimeInSeconds: number;
    translationUsageInSeconds: number;
  };

  // One data-point per timestamp, group by timestamp
  const timeUsagesGroupedByTimestamp = useMemo(() => {
    return timeUsages.reduce(
      (result: TimeUsageGrouped[], current: ITimeUsage) => {
        const existing = result.find(
          (usage) =>
            new Date(usage.timestamp).getTime() ===
            new Date(current.timestamp).getTime()
        );
        if (existing) {
          existing.inCallTimeInSeconds += current.intervalInSeconds;
          existing.translationUsageInSeconds += !current.muted
            ? current.intervalInSeconds
            : 0;
        } else {
          result.push({
            timestamp: new Date(current.timestamp),
            inCallTimeInSeconds: current.intervalInSeconds,
            translationUsageInSeconds: !current.muted
              ? current.intervalInSeconds
              : 0,
          });
        }
        return result;
      },
      []
    );
  }, [timeUsages]);

  // const inCallTimeInSeconds = useMemo(() => {
  //   const maxTimeGap = 60 * 1000;
  //   return timeUsagesGroupedByTimestamp.map((usage, index, arr) => {
  //     const current = new Date(usage.timestamp);
  //     const previous = index > 0 ? new Date(arr[index - 1].timestamp) : null;
  //     const timeGap = previous ? current.getTime() - previous.getTime() : null;

  //     if (timeGap && timeGap > maxTimeGap) {
  //       previous!.setMinutes(previous!.getMinutes() + 1);
  //       return {
  //         x: null,
  //         y: null,
  //       };
  //     }
  //     return {
  //       x: current,
  //       y: usage.inCallTimeInSeconds,
  //     };
  //   });
  // }, [timeUsagesGroupedByTimestamp]);

  const translationUsageInSeconds = useMemo(() => {
    const maxTimeGap = 60 * 1000;
    return timeUsagesGroupedByTimestamp.map((usage, index, arr) => {
      const current = new Date(usage.timestamp);
      const previous = index > 0 ? new Date(arr[index - 1].timestamp) : null;
      const timeGap = previous ? current.getTime() - previous.getTime() : null;

      if (timeGap && timeGap > maxTimeGap) {
        previous!.setMinutes(previous!.getMinutes() + 1);
        return {
          x: null,
          y: null,
        };
      }
      return {
        x: current,
        y: usage.translationUsageInSeconds,
      };
    });
  }, [timeUsagesGroupedByTimestamp]);

  // const captionUsageInSeconds = useMemo(() => {
  //   return usages.map((usage, index, arr) => {
  //     const currentStart = new Date(usage.startTimestamp);
  //     const previousEnd =
  //       index > 0 ? new Date(arr[index - 1].endTimestamp) : null;
  //     const timeGap = previousEnd
  //       ? currentStart.getTime() - previousEnd.getTime()
  //       : null;

  //     if (timeGap && timeGap > 60 * 1000) {
  //       previousEnd!.setMinutes(previousEnd!.getMinutes() + 1);
  //       return {
  //         x: null,
  //         y: null,
  //       };
  //     }
  //     return {
  //       x: currentStart,
  //       y:
  //         (new Date(usage.endTimestamp).getTime() -
  //           new Date(usage.startTimestamp).getTime()) /
  //         1000,
  //     };
  //   });
  // }, [usages]);

  // const charactersTranslated = useMemo(() => {
  //   return usages.map((usage, index, arr) => {
  //     const currentStart = new Date(usage.startTimestamp);
  //     const previousEnd =
  //       index > 0 ? new Date(arr[index - 1].endTimestamp) : null;
  //     const timeGap = previousEnd
  //       ? currentStart.getTime() - previousEnd.getTime()
  //       : null;

  //     if (timeGap && timeGap > 60 * 1000) {
  //       previousEnd!.setMinutes(previousEnd!.getMinutes() + 1);
  //       return {
  //         x: null,
  //         y: null,
  //       };
  //     }
  //     return {
  //       x: currentStart,
  //       y: usage.finalCharactersTranslated,
  //     };
  //   });
  // }, [usages]);

  return (
    <div className="TranslationUsage">
      <h3>Translation Usage</h3>
      <div className="TranslationUsageInfo">
        <div>
          <div>
            Total Translation Usage in Minutes:{" "}
            {(totalTranslationUsageInSeconds / 60).toFixed(2)}
          </div>
          {/* <div>Total Characters Translated: {finalCharactersTranslated}</div> */}
        </div>
        {/* <div>
          <div>
            Participants:
            <ul>
              {Object.entries(participantUsages).map(
                ([userId, usage], index) => (
                  <li key={index}>
                    <div>{userId}</div>
                    <div>
                      In-Call Time in Seconds: {usage.inCallTimeInSeconds}
                    </div>
                    <div>
                      Translation Usage in Seconds:{" "}
                      {usage.translationUsageInSeconds}
                    </div>
                  </li>
                )
              )}
            </ul>
          </div>
          <div>Total In-Call Time in Seconds: {totalInCallTimeInSeconds}</div>

          <div>
            Total Caption Usage in Seconds: {totalCaptionUsageInSeconds}
          </div>
          <div>Total Captions Translated: {totalCaptionsTranslated}</div>

          <div>
            Total Characters Translated (Including Interim):{" "}
            {totalCharactersTranslated}
          </div>
        </div> */}
      </div>
      <div className="TranslationUsageChart">
        <Line
          datasetIdKey={datasetId}
          title="Translation Usage"
          options={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                type: "time",
                ticks: {
                  source: "auto",
                },
                adapters: {
                  date: {
                    locale: enUS,
                  },
                },
                time: {
                  unit: selectedTimeUnit,
                },
                min: timeSlice.start.getTime(),
                max: timeSlice.end.getTime(),
              },
              y: {
                beginAtZero: true,
              },
            },
          }}
          data={{
            datasets: [
              // {
              //   label: "In-Call Time in Seconds",
              //   backgroundColor: "#0079d0",
              //   borderColor: "#0079d0",
              //   data: inCallTimeInSeconds,
              //   hidden: true,
              // },
              {
                label: "Translation Usage in Seconds",
                backgroundColor: "#e76399",
                borderColor: "#e76399",
                data: translationUsageInSeconds,
              },
              // {
              //   label: "Caption Usage in Seconds",
              //   backgroundColor: "#ffab00",
              //   borderColor: "#ffab00",
              //   data: captionUsageInSeconds,
              //   hidden: true,
              // },
              // {
              //   label: "Characters Translated",
              //   data: charactersTranslated,
              //   backgroundColor: "#00814a",
              //   borderColor: "#00814a",
              //   hidden: true,
              // },
            ],
          }}
        />
      </div>
      <div className="TranslationUsageChartModifiers">
        <div>
          <div>Unit Scale</div>
          <div>
            {TIME_UNITS.map((unit, index) => {
              const label = unit.charAt(0).toUpperCase() + unit.slice(1);
              return (
                <Button
                  key={index}
                  text={label}
                  background={
                    selectedTimeUnit === unit ? "gradient" : undefined
                  }
                  onClick={() => setSelectedTimeUnit(unit)}
                />
              );
            })}
          </div>
        </div>
        <div>
          <div>Time Range</div>
          <div>
            {/* Start date */}
            <DatePicker
              selected={timeSlice.start}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={1}
              dateFormat="yyyy-MM-dd HH:mm"
              onChange={(date) =>
                setTimeSlice((prev) => (date ? { ...prev, start: date } : prev))
              }
            />
            {/* End date */}
            <DatePicker
              selected={timeSlice.end}
              showTimeSelect
              timeFormat="HH:mm"
              dateFormat="yyyy-MM-dd HH:mm"
              timeIntervals={1}
              onChange={(date) =>
                setTimeSlice((prev) => (date ? { ...prev, end: date } : prev))
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Usage;
