import { Participant, SpeechLanguageLocale } from "byrdhouse-types";
import { CSSProperties, useCallback, useEffect, useState } from "react";
import {
  useCurrentLanguage,
  useGetParticipantCurrentLanguage,
  useLocalParticipant,
} from "../hooks/user";
import { speechLanguageLocaleToFlag } from "../utils";
import "./Stream.css";
import {
  ILocalAudioTrack,
  ILocalVideoTrack,
  IRemoteAudioTrack,
  IRemoteVideoTrack,
  VideoPlayerConfig,
} from "agora-rtc-sdk-ng";
import { AgoraVideoPlayer } from "agora-rtc-react";
import {
  IoDesktop,
  IoEllipsisVertical,
  IoMicOff,
  IoPeople,
  IoSync,
  IoTrash,
} from "react-icons/io5";
import useCall, {
  useMuteUser,
  usePinSpeaker,
  // usePinSpeaker,
  useReassignHost,
  useRemoveUser,
  useRenderHostControls,
} from "../hooks/call";
import { AiFillPushpin } from "react-icons/ai";
import { FiMic, FiMicOff } from "react-icons/fi";
import MediaSelector from "./MediaSelector";
import ToolbarOverflow from "./ToolbarOverflow";
import { useGetInitials } from "../hooks/utils";

type PrecallControlsProps = {
  isMobile: boolean;
};
const PrecallControls = ({ isMobile }: PrecallControlsProps) => {
  return (
    <div className="PrecallControls">
      <MediaSelector precall={true} />
      {!isMobile ? <ToolbarOverflow precall={true} /> : null}
    </div>
  );
};

export type StreamProps = {
  videoTrack?: IRemoteVideoTrack | ILocalVideoTrack;
  audioTrack?: IRemoteAudioTrack | ILocalAudioTrack;
  user: Participant;
  local?: boolean;
  mirror?: boolean;
  fit?: "cover" | "contain" | "fill";
  screenShareLoading?: boolean;
  precall?: boolean;
  style?: CSSProperties;
  mobilePrecall?: boolean;
  isSpeaker?: boolean;
  borderRadius?: boolean;
};

const Stream = (props: StreamProps) => {
  const localParticipant = useLocalParticipant();
  const [call] = useCall();
  const [localTime, setLocalTime] = useState<string | undefined>(undefined);
  const [, /*isDayTime*/ setIsDayTime] = useState<boolean>(true);

  useEffect(() => {
    let interval: NodeJS.Timer;

    const setTime = (
      speechLanguageLocale: SpeechLanguageLocale,
      timezone: string
    ) => {
      let date = new Date();
      let hours = date.getHours();
      setIsDayTime(hours > 6 && hours < 18);
      setLocalTime(
        date.toLocaleTimeString(speechLanguageLocale.code, {
          hour: "numeric",
          minute: "2-digit",
          timeZone: timezone,
        })
      );
    };

    if (localParticipant) {
      interval = setInterval(() => {
        // Always set using local users locale code with remote users timezone
        setTime(localParticipant.speechLanguageLocale, props.user.timezone);
        // Update every 1s to keep up with time
      }, 1000);
    }

    if (localParticipant) {
      setTime(localParticipant.speechLanguageLocale, props.user.timezone);
    }

    return () => {
      clearInterval(interval);
    };

    // Remote user time
  }, [setLocalTime, props.user.timezone, localParticipant]);

  // Display volume level of stream
  const [volume, setVolume] = useState(0);
  useEffect(() => {
    const setVolumeLevelInterval = setInterval(() => {
      if (props.user.muted) {
        setVolume(0);
      } else {
        setVolume(props.audioTrack?.getVolumeLevel() || 0);
      }
    }, 200);

    return () => {
      clearInterval(setVolumeLevelInterval);
    };
  }, [props.user.muted, props.audioTrack]);

  const currentLanguage = useCurrentLanguage();
  const getParticipantCurrentLanguage = useGetParticipantCurrentLanguage();

  // Set volume of remote stream lower when dub enabled
  useEffect(() => {
    // Don't set volume of our own stream
    if (props.user.sessionId === localParticipant?.sessionId) {
      return;
    }
    // If dub enabled and remote peer speaks different language, lower volume
    if (
      call.dub &&
      getParticipantCurrentLanguage(props.user)?.code !==
        currentLanguage?.code &&
      call.translationProvider.voiceToVoiceEnabled
    ) {
      props.audioTrack?.setVolume(10);
    } else {
      props.audioTrack?.setVolume(100);
    }
  }, [
    props.audioTrack,
    call.dub,
    call.translationProvider,
    props.user,
    props.user.speechLanguageLocale,
    props.user.detectedLanguage,
    getParticipantCurrentLanguage,
    currentLanguage?.code,
    localParticipant?.sessionId,
    call.remotePeers,
  ]);

  let config: VideoPlayerConfig = {};
  if (props.mirror !== undefined) {
    config.mirror = props.mirror;
  }
  config.fit = props.fit || "cover";

  // Host dropdown functions
  const [showHostDropdown, setShowHostDropdown] = useState<boolean>(false);
  const onClickShowHostDropdown = useCallback(() => {
    setShowHostDropdown((prevState) => !prevState);
  }, []);

  const HostControlsDropDown = (props: { user: Participant }) => {
    const muteUserHook = useMuteUser();
    const muteUser = useCallback(() => {
      muteUserHook(props.user);
    }, [props.user, muteUserHook]);

    const reassignHostHook = useReassignHost();
    const reassignHost = useCallback(() => {
      reassignHostHook(props.user);
    }, [props.user, reassignHostHook]);

    const removeUserHook = useRemoveUser();
    const removeUser = useCallback(() => {
      removeUserHook(props.user, false);
    }, [props.user, removeUserHook]);

    return (
      <div className="HostControlsDropDown">
        <div onClick={muteUser}>
          <IoMicOff />
          Mute
        </div>
        <div onClick={reassignHost}>
          <IoPeople />
          Reassign Host
        </div>
        <div onClick={removeUser}>
          <IoTrash />
          Remove
        </div>
      </div>
    );
  };

  const [showCulturalContext, setShowCulturalContext] =
    useState<boolean>(false);
  const onHover = useCallback(() => {
    setShowCulturalContext(true);
  }, [setShowCulturalContext]);

  const onUnHover = useCallback(() => {
    setShowCulturalContext(false);
  }, [setShowCulturalContext]);

  const getInitials = useGetInitials();

  const pinSpeaker = usePinSpeaker(props.user.sessionId);

  const renderHostControls = useRenderHostControls();
  const userHasHostControls =
    localParticipant && renderHostControls(localParticipant) && !props.local;
  const fontSize = props.style?.fontSize
    ? parseInt(props.style.fontSize.toString()) + 2
    : 18;
  const tooltipSize = fontSize < 18 ? "SmallTooltip" : "MediumTooltip";
  return (
    <div
      className={`Stream ${props.borderRadius ? "BorderRadius" : ""}`}
      style={{
        ...props.style,
        border: props.borderRadius
          ? `2px solid rgba(231, 99, 153, ${
              volume > 0.5 && Object.values(call.remotePeers).length >= 2
                ? volume
                : 0
            })`
          : undefined,
      }}
      onMouseEnter={onHover}
      onMouseLeave={onUnHover}
    >
      <span className={`StreamTooltip ${tooltipSize} StreamUser`}>
        {(props.user.autoDetect
          ? "✨"
          : speechLanguageLocaleToFlag(props.user.speechLanguageLocale)) +
          " " +
          props.user.displayName}
        {renderHostControls(props.user) && " (Host)"}
        {props.user.sessionId !== localParticipant?.sessionId &&
          Object.values(call.remotePeers).length >= 2 && (
            <AiFillPushpin
              onClick={pinSpeaker}
              className={
                props.user.sessionId === call.pinnedSpeaker ? "Pinned" : ""
              }
            />
          )}
      </span>

      {props.user.culturalContext && showCulturalContext && (
        <span className={`StreamTooltip ${tooltipSize} StreamCulturalContext`}>
          <span>
            <img
              src={props.user.culturalContext.weather.icon}
              alt={props.user.culturalContext.weather.text}
            />
            {props.user.culturalContext.weather.text}
          </span>
          <span>
            <span>📍</span>
            {props.user.culturalContext.location.country}
          </span>
        </span>
      )}
      <span className={`StreamTooltip ${tooltipSize} StreamTime`}>
        {/* <span>{isDayTime ? "🌆" : "🌃"}</span> */}
        <span>{localTime}</span>
      </span>
      <span
        className={`StreamTooltip ${tooltipSize} StreamMic ${
          userHasHostControls ? "PadForHost" : ""
        }`}
      >
        {!props.user.muted ? <FiMic /> : <FiMicOff />}
      </span>
      {userHasHostControls && (
        <span
          className={`StreamTooltip ${tooltipSize} HostEllipsis`}
          onClick={onClickShowHostDropdown}
        >
          <IoEllipsisVertical />
          {showHostDropdown && <HostControlsDropDown user={props.user} />}
        </span>
      )}
      {props.screenShareLoading && (
        <div className="ScreenShareLoading">
          <IoDesktop className="ScreenShareIcon" />
          <IoSync className="Loading" />
        </div>
      )}
      {props.precall && <PrecallControls isMobile={!!props.mobilePrecall} />}
      {!props.screenShareLoading &&
      props.videoTrack &&
      // Firefox fix for setEnabled since agora react player is actual hot garbage
      (!props.local || (props.local && !call.cameraMuted)) ? (
        <AgoraVideoPlayer
          videoTrack={props.videoTrack}
          config={config}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      ) : (
        <div className="Initials">
          <div
            style={props.isSpeaker ? { width: 120, height: 120 } : undefined}
          >
            {getInitials(props.user.displayName)}
          </div>
        </div>
      )}
    </div>
  );
};

export default Stream;
